import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import {
  AquacomComponentCategory,
  MooringArticleCategory,
  WeightUnitType,
  categoryDimensionTypes,
  getArticleWeightType,
  weightUniOptions,
} from 'models';
import { MooringArticleCategoryType } from 'models/MooringArticleCategoryType';
import { MooringArticleCategoryTypeService } from 'services/mooring-article-category-type-service';
import { MooringArticleCategoryService } from 'services';

const a = (t: any) =>
  JSON.parse(JSON.stringify(categoryDimensionTypes)).map((x) => {
    x.label = t.tr(x.label);
    return x;
  });

@autoinject
export class MooringArticleCategoryCreateEdit {
  @bindable
  @observable
  model: MooringArticleCategory = new MooringArticleCategory();
  modelChanged(model: MooringArticleCategory) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable
  onSave: (data: { data: MooringArticleCategory }) => void;

  @bindable
  onCanceled: () => void;

  @bindable
  @observable
  visible = false;
  visibleChanged(visible: boolean) {
    if (visible) {
      void this.getCategoryTypes();
    }
  }

  protected componentTypes: MooringArticleCategoryType[] = [];

  protected aquacomCategories: AquacomComponentCategory[] = [];

  protected form: MooringArticleCategory = {
    Id: undefined,
    Name: undefined,
    NameEn: undefined,
    NameEs: undefined,
    PrimaryDimensionType: undefined,
    SecondaryDimensionType: undefined,
    WeightUnitType: WeightUnitType.Kg_m,
    MooringArticleCategoryTypeId: undefined,
  };

  protected primaryDimensionTypes = a(this.t);
  protected secondaryDimensionTypes = a(this.t);

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private mooringArticleCategoryService: MooringArticleCategoryService,
    private mooringArticleCategoryTypeService: MooringArticleCategoryTypeService,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NameEn')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('PrimaryDimensionType')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('MooringArticleCategoryTypeId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  protected weightUnitOptions = weightUniOptions().map((x) => {
    x.label = this.t.tr(x.label);
    return x;
  });

  protected getWeightUnitLabel(type: WeightUnitType) {
    return getArticleWeightType(type);
  }

  attached() {
    this.applyValidationRules();
    void this.getAquacomCategories();
  }

  protected async getAquacomCategories() {
    try {
      this.aquacomCategories = await this.mooringArticleCategoryService.getAquacomCategoryTypes();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getCategoryTypes() {
    try {
      this.componentTypes = await this.mooringArticleCategoryTypeService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
