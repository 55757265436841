import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

@autoinject
export class CustomerDialog {
  protected customerId: number;

  constructor(protected dialogController: DialogController) {}

  activate(params: { customerId: number }) {
    this.customerId = params.customerId;
  }
}
