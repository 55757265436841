import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import { CreateMooringArticle, MooringArticle } from 'models';
import { ErrorService, MooringArticleService } from 'services';
import { ToastService } from 'services/toast-service';

@autoinject
export class MooringArticleDialog {
  protected article: MooringArticle;
  protected ready: boolean = false;
  protected form?: CreateMooringArticle;

  protected isSaving = false;

  protected similarArticleId?: number;

  constructor(
    private dialogController: DialogController,
    private errorService: ErrorService,
    private articleService: MooringArticleService,
    private toastService: ToastService,
    private pubSub: PubSub
  ) {}

  // Aurelia lifecycle
  protected async activate(model: { articleId: number }) {
    if (model?.articleId) {
      await this.setup(model.articleId);
    }
    this.ready = true;
  }

  protected articleToEdit: MooringArticle;
  protected async getArticle(id: number) {
    try {
      this.article = await this.articleService.get(id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async setup(articleId?: number) {
    if (articleId) {
      await this.getArticle(articleId);
      this.form = {
        Id: this.article.Id,
        Inactive: this.article.Inactive,
        SupplierId: this.article.SupplierId,
        CategoryId: this.article.CategoryId || undefined,
        TypeId: this.article.TypeId || undefined,
        ProductTypeId: this.article.ProductTypeId || undefined,
        MBL: this.article.MBL,
        WLL: this.article.WLL,
        OverrideCertificateProducer: this.article.OverrideCertificateProducer,
        WeightInAir: this.article.WeightInAir,
        WeightInWater: this.article.WeightInWater,
        PrimaryDimension: this.article.PrimaryDimension,
        SecondaryDimension: this.article.SecondaryDimension,
        CriteriaForValidityCategoryId: this.article.CriteriaForValidityCategoryId,
        ArticleProducts:
          this.article?.ArticleProducts?.map((x) => ({
            Id: x.Id,
            MooringArticleId: x.MooringArticleId,
            ProductId: x.ProductId,
          })) || [],
        MooringArticleMaterials:
          this.article?.MooringArticleMaterials?.map((x) => ({
            Id: x.Id,
            MooringArticleId: x.MooringArticleId,
            MaterialTypeId: x.MaterialTypeId,
            MaterialAmountType: x.MaterialAmountType,
            MaterialAmountInProduct: x.MaterialAmountInProduct,
            Name: x.Name,
            NameEn: x.NameEn,
            NameEs: x.NameEs,
          })) || [],
      };
    }
  }

  protected async hasSimilarArticle(article: CreateMooringArticle) {
    this.similarArticleId = undefined;
    if (!this.form) {
      return false;
    }
    try {
      const { articleId } = await this.articleService.hasSimilar(article);
      const hasSimilar = articleId && articleId > 0;
      if (hasSimilar) {
        this.similarArticleId = articleId;
        return hasSimilar;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    return false;
  }

  protected async onSave(data: CreateMooringArticle) {
    this.isSaving = true;
    if (await this.hasSimilarArticle(data)) {
      this.isSaving = false;
      return;
    }
    try {
      // validate id
      let id = data?.Id;
      if (!data.WLL) data.WLL = null;
      if (id) {
        await this.articleService.update(data, data.Id);
        this.pubSub.publish('list-entity:updated', null);
      } else {
        const response = await this.articleService.create(data);
        id = response.Id;
        this.pubSub.publish('list-entity:created', null);
      }
      await this.setup(id);
      this.toastService.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isSaving = false;
  }

  protected onCancel() {
    void this.dialogController.close(true);
  }
}
